<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <!--      <el-button icon="el-icon-back" type="info" size="mini" circle plain style="margin-right: 15px"-->
      <!--                 @click="$router.push('/')"></el-button>-->
      <el-form-item :label="$t('userFace.query.usernameLabel')" prop="username">
        <el-input
            v-model="queryParams.username"
            :placeholder="$t('userFace.query.usernamePlaceholder')"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item :label="$t('userFace.query.nickNameLabel')" prop="nickName">
        <el-input
            v-model="queryParams.nickName"
            :placeholder="$t('userFace.query.nickNamePlaceholder')"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{ $t('userFace.search') }}
        </el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">{{ $t('userFace.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="userList" class="table-box" max-height="760"
              :header-cell-style="{background:'#eef1f6',color:'#606266'}">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div style="padding-left: 36px;padding-right: 10px">
            <el-table :data="props.row.userFaces" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column
                  :label="$t('userFace.table.indexLabel')"
                  width="200"
                  align="center"
                  type="index"
              >
              </el-table-column>
              <el-table-column :label="$t('userFace.table.dataUserNameLabel')" align="center" key="name" prop="name"/>
              <el-table-column prop="fileUrl" :label="$t('userFace.table.imageLabel')" min-width="20%">
                <template slot-scope="scope">
                  <el-image
                      style=" height: 70px;min-width: 70px;"
                      :src="scope.row.fileUrl"
                      :preview-src-list="[scope.row.fileUrl]"
                      fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="encoding" :label="$t('userFace.table.dataLabel')">
                <template slot-scope="scope">
                  <el-tag :type="(scope.row.encoding==='0')?'success':'danger'">
                    {{
                      $t((scope.row.encoding === '0') ? "userFace.dataValue.existence" : "userFace.dataValue.absence")
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                  width="300"
                  :label="$t('userFace.table.operateLabel')"
                  align="center"
                  class-name="small-padding fixed-width"
              >
                <template slot-scope="scope">
                  <el-button
                      size="mini"
                      type="text"
                      icon="el-icon-edit"
                      @click="openUpload(scope.row)"
                  >{{ $t('common.modify') }}
                  </el-button>
                  <el-button
                      size="mini"
                      type="text"
                      icon="el-icon-delete"
                      @click="delUserFace(scope.row)"
                  >{{ $t('common.delete') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('userFace.table.quantityLabel')" align="center">
        <template slot-scope="props">
          <span>{{ props.row.userFaces.length + "/5" }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('userFace.table.idLabel')" align="center" key="userId" prop="userId"/>
      <el-table-column :label="$t('userFace.table.usernameLabel')" align="center" key="username" prop="username"
                       :show-overflow-tooltip="true"/>
      <el-table-column :label="$t('userFace.table.nickNameLabel')" align="center" key="nickName" prop="nickName"
                       :show-overflow-tooltip="true"/>
      <el-table-column
          width="300"
          :label="$t('userFace.table.operateLabel')"
          align="center"
          class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="openUpload(scope.row)">
            {{ $t('userFace.submit') }}<i class="el-icon-upload el-icon--right"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />
    <el-dialog :title="$t('userFace.dialogTitle')" :visible.sync="open" width="800px" append-to-body
               @opened="modalOpened"
               @close="closeDialog">
      <userAvatar :user="uploadUser" ref="uploadAvatar" @submit="uploadImg"></userAvatar>
    </el-dialog>
  </div>
</template>
<script>
import userAvatar from './userAvatar'
import {getList, upload, deleteUserFace} from "@/api/userface";

export default {
  name: "UserFace",
  components: {userAvatar},
  data() {
    return {
      loading: true,
      showSearch: true,
      open: false,
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        username: undefined,
        nickName: undefined,
      },
      userList: [],
      uploadUser: undefined,
    }
  }
  ,
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      getList(this.queryParams).then(res => {
        this.userList = res.data.rows;
        this.total = res.data.total;
        this.loading = false;
      })
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    resetQuery() {
      this.$refs['queryForm'].resetFields()
      this.handleQuery();
    },
    openUpload(data) {
      this.open = true
      this.uploadUser = {};
      if (data !== undefined) {
        this.uploadUser = {id: data.id, userId: data.userId, name: data.name, fileUrl: data.fileUrl};
      }
    },
    modalOpened() {
      this.$refs.uploadAvatar.modalOpened();
    },
    closeDialog() {
      this.$refs.uploadAvatar.closeDialog();
      this.open = false;
    },
    uploadImg(data, form) {
      let formData = new FormData();
      formData.append("file", data);
      formData.append("userId", form.userId);
      formData.append("name", form.name);
      if (form.id !== undefined) {
        formData.append("id", form.id);
      }
      upload(formData).then(res => {
        this.$message.success(this.$t('tips.editSuccess'));
        setTimeout(() => {
          this.closeDialog();
          this.getList();
        }, 1500)
      })
    },
    delUserFace(data) {
      const tips = this.$t('common.popTips')
      const messages = "是否要删除名称[" + data.name + "]人脸数据?"
      this.$confirm(messages, tips, {
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        deleteUserFace(data.id).then(res => {
          this.$message.success(this.$t("tips.deleteSuccess"))
          this.getList();
        })
      })
    },
  }
}
</script>

<style lang="scss">
.app-container {
  padding: 36px;
}

.table-box {
  width: 100%;
}
</style>
