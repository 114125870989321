import request from '@/api/request.js'

// 查询用户列表
export function getList(query) {
    return request({
        url: '/admin/userface/list',
        method: 'get',
        params: query
    })
}

// 上传
export function upload(data) {
    return request({
        url: '/admin/userface/upload',
        method: 'post',
        data: data
    })
}

export function deleteUserFace(id) {
    return request({
        url: '/admin/userface/' + id,
        method: 'delete',
    })
}
