<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" label-position="top">
      <el-form-item label="姓名">
        <el-input v-model="form.name" maxlength="20" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="照片">
        <el-row>
          <el-col :xs="24" :md="12" :style="{height: '350px'}">
            <vue-cropper
                ref="cropper"
                :img="options.img"
                :info="true"
                :autoCrop="options.autoCrop"
                :autoCropWidth="options.autoCropWidth"
                :autoCropHeight="options.autoCropHeight"
                :fixedBox="options.fixedBox"
                :outputType="options.outputType"
                @realTime="realTime"
                v-if="visible"
            />
          </el-col>
          <el-col :xs="24" :md="12" :style="{height: '350px'}">
            <div class="avatar-upload-preview" v-if="options.img">
              <img :src="previews.url" :style="previews.img"/>
            </div>
          </el-col>
        </el-row>
        <br/>
        <el-row>
          <el-col :lg="2" :sm="3" :xs="3">
            <el-upload action="#" :http-request="requestUpload" :show-file-list="false" :before-upload="beforeUpload">
              <el-button size="small">
                {{ $t('common.select') }}
                <i class="el-icon-upload el-icon--right"></i>
              </el-button>
            </el-upload>
          </el-col>
          <el-col :lg="{span: 1, offset: 2}" :sm="2" :xs="2">
            <el-button icon="el-icon-plus" size="small" @click="changeScale(1)"></el-button>
          </el-col>
          <el-col :lg="{span: 1, offset: 1}" :sm="2" :xs="2">
            <el-button icon="el-icon-minus" size="small" @click="changeScale(-1)"></el-button>
          </el-col>
          <el-col :lg="{span: 1, offset: 1}" :sm="2" :xs="2">
            <el-button icon="el-icon-refresh-left" size="small" @click="rotateLeft()"></el-button>
          </el-col>
          <el-col :lg="{span: 1, offset: 1}" :sm="2" :xs="2">
            <el-button icon="el-icon-refresh-right" size="small" @click="rotateRight()"></el-button>
          </el-col>
          <el-col :lg="{span: 2, offset: 6}" :sm="2" :xs="2">
            <el-button type="primary" size="small" @click="uploadImg()">{{ $t('common.submit') }}</el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {VueCropper} from "vue-cropper";
// import {uploadAvatar} from "@/api/system/user";
import {debounce} from '@/utils'

export default {
  components: {VueCropper},
  props: {
    user: {
      type: Object
    },
  },
  data() {
    return {
      // 是否显示cropper
      visible: false,
      // 弹出层标题
      title: "上传",
      form: {},
      options: {
        img: '', //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        outputType: "png" // 默认生成截图为PNG格式
      },
      previews: {},
      resizeHandler: null
    };
  },
  methods: {
    // 打开弹出层结束时的回调
    modalOpened() {
      this.visible = true;
      if (this.user !== undefined) {
        let user = JSON.parse(JSON.stringify(this.user));
        this.form = {id: user.id,userId: user.userId, name: user.name}
        this.options.img = user.fileUrl
      }
      if (!this.resizeHandler) {
        this.resizeHandler = debounce(() => {
          this.refresh()
        }, 100)
      }
      window.addEventListener("resize", this.resizeHandler)
    },
    // 刷新组件
    refresh() {
      this.$refs.cropper.refresh();
    },
    // 覆盖默认的上传行为
    requestUpload() {
    },
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") === -1) {
        this.$message.warning("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。")
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.img = reader.result;
        };
      }
    },
    // 上传图片
    uploadImg() {
      this.$refs.cropper.getCropBlob(data => {
        this.$emit("submit", data, this.form)
      });
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    // 关闭窗口
    closeDialog() {
      this.options.img = ''
      this.visible = false;
      window.removeEventListener("resize", this.resizeHandler)
    }
  }
};
</script>
<style scoped lang="scss">
.user-info-head {
  position: relative;
  display: inline-block;
  height: 120px;
}

.user-info-head:hover:after {
  content: '+';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #eee;
  background: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  line-height: 110px;
  border-radius: 50%;
}

.avatar-upload-preview {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 10%;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
}
</style>
